import useI18n from '../../tools/i18n.js'

function Header({ lang, setLang }) {
  const t = useI18n(lang)

  return (
    <>
    <header id="header">
      <h1 className="uppercase">{t.t('itour')}</h1>
      <h3 className="uppercase">{t.t('subtitle')}</h3>
      <p>{t.t('soon')}</p>
    </header>
    </>
  );
}

export default Header;
