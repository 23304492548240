import './App.css'
import { useState } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Content from './components/Content'

function App() {
  const [lang, setLang] = useState('es')

  return (
    <div className="App flex flex-col justify-center">
      <Header lang={lang} setLang={setLang} />
      <Content lang={lang} />
      <Footer lang={lang} />
    </div>
  );
}

export default App
