import Footer from './../Footer'
import FoodMenu from './../FoodMenu'
import menu from './menu.json'
import { Link } from "react-router-dom";

function Cafe() {
  return (
    <>
      <div className="flex justify-around">
        <h2><Link className="opacity-60" to="/carta">RESTAURANTE</Link></h2>
        <h2><Link className="opacity-100" to="/cafe">TERRAZA</Link></h2>
      </div>

      <FoodMenu menu={menu} />

      <br />
      <Footer lang="es" />
    </>
  );
}

export default Cafe;
