function FoodMenu({ menu }) {
  return menu.map(section => {
    const cssClass = `bg-white rounded-md text-black p-4 pb-${section.extras? "4" : "2"} bg-opacity-80`

    return (
      <>
        <SectionTitle
          title={section.title.es}
          subtitle={section.subtitle?.es}
        />
        <div className={cssClass}>
          {section.items.map(item => (
            <Item name={item.name.es} price={item.price.es} />
          ))}

          {section.extras?.map(extra => (
            <>
              <span className="italic opacity-80">{extra.es}</span>
              <br />
            </>
          ))}
        </div>
      </>
    )
  })
}

function SectionTitle({ title, subtitle }) {
  return (
    <>
      <h2 className="mt-12 mb-0">{title}</h2>
      {subtitle && (
        <h5 className="px-0 font-normal italic opacity-80 mb-1">{subtitle}</h5>
      )}
    </>
  )
}

function Item({ name, price }) {
  return (
    <div className="flex justify-between font-bold opacity-80 mb-4">
      <span>{name}</span>
      <span className="text-right">{price}</span>
    </div>
  )
}

export default FoodMenu;
