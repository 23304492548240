import translations from './translations.json'
import { useState, useEffect } from 'react'

const useI18n = (lang = 'es') => {
  const initialT = i18n(lang)
  const [t, setT] = useState(initialT)

  useEffect(() => {
    setT(i18n(lang))
  }, [lang])

  return t
}


function i18n(lang) {
  return ({
    t: k => translations[lang][k]
  });
}

export default useI18n
