import useI18n from '../../tools/i18n.js'

function Footer({ lang }) {
  const t = useI18n(lang)

  return (
    <footer id="footer">
      <ul className="icons">
        <li>
          <a href="https://www.facebook.com/people/ItourHotelCoffee-Hotel-y-Restaurante/61568330742014/" target="_blank" rel="noreferrer" className="icon brands fa-facebook">
            <span className="label">{t.t('twitter')}</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/itourhotelcoffee/profilecard" target="_blank" rel="noreferrer" className="icon brands fa-instagram">
            <span className="label">{t.t('instagram')}</span>
          </a>
        </li>
        <li>
          <a href="mailto:contacto@itourhotel.com" target="_blank" rel="noreferrer" className="icon fa-envelope">
            <span className="label">Email</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; {t.t('copyright')}</li>
        <li>{t.t('address')}</li>
      </ul>
    </footer>
  );
}

export default Footer;
